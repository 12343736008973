import { configureStore } from "@reduxjs/toolkit";
import entryReducer from "./entrySlice"; // Import your entry reducer
import userReducer from "./userSlice"; // Import your user reducer
import cloudReducer from "./cloudSlice"; // Import your loading reducer
import entriesReducer from "./entriesSlice";
import uIReducer from "./uiSlice";
import dataReducer from "./dataSlice";
import { da } from "date-fns/locale";

const store = configureStore({
  reducer: {
    entry: entryReducer,
    entries: entriesReducer,
    user: userReducer,
    ui: uIReducer,
    cloud: cloudReducer,
    data: dataReducer,
    // Add more reducers as needed
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
